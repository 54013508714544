<template>
  <div class="CMForm">
    <b-form>
      <b-form-group id="select-group-1" label="従業員名" label-for="select" class="required">
        <common-multiple
          :value.sync="selectedEmployeeId"
          :multiple="false"
          :close-on-select="true"
          :disabled="isEditForm"
          track-by="id"
          label="name"
          :options="customerNameOptions"
          :error="!!errorMessages.form.id"
          no-options-label=""
          no-result-label="該当データが存在しません。"
        />
        <span v-if="!!errorMessages.form.id" class="label-error">{{ errorMessages.form.id }} </span>
      </b-form-group>

      <b-form-group id="input-group-1" label="メールアドレス" label-for="input-1" class="required">
        <b-form-input id="input-1" :value="form.email" type="text" disabled />
      </b-form-group>
      <!--disable autocomplete-->
      <label>
        <input name="name" type="text" style="height: 0; width: 0; position: fixed; top: -1000px" />
      </label>
      <b-form-group id="input-group-2" label="パスワード" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            type="password"
            autocomplete="new-password"
            :value="dummyPassword"
            :formatter="formatter"
            :disabled="isEditForm"
            :class="status($v.form.password, true)"
            @change="setFormValue('password', $event)"
            @click="resetDummyPassword"
          />
          <b-input-group-append v-if="status($v.form.password, true).invalid" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="status($v.form.password, true).invalid" class="label-error"
          >{{ errorMessages.form.password }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-1" label="権限" label-for="select-2" class="required">
        <b-form-select
          id="select-2"
          :value="form.role_id"
          class="form-select"
          size="sm"
          :options="rolesOptions"
          :class="status($v.form.role_id)"
          @change="setFormValue('role_id', $event)"
        />
        <span v-if="!!errorMessages.form.role_id" class="label-error"
          >{{ errorMessages.form.role_id }}
        </span>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { helpers, required } from 'vuelidate/lib/validators'
import { FORM_MODE, LOGIN_STATUS, MAX_LENGTH, PASSWORD_DUMMY } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import CommonMultiple from '@/components/commons/common-multiselect'

export default {
  name: 'ListOfInternalMemberModalForm',
  components: { CommonMultiple },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    dataCustomer: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      form: {},
      login_accepted: null,
      formMode: FORM_MODE.ADD,
      customersData: [],
      rolesOptions: [],
      customerNameOptions: [],
      selectedRole: '',
      selectedMemberName: '',
      dummyPassword: '',
      errorMessages: {
        form: {
          id: '',
          password: '',
          role_id: '',
        },
      },
      selectedEmployeeId: {},
    }
  },
  validations: {
    form: {
      id: {
        required,
      },
      password: {
        required,
        // at least: 8 chars, 1 uppercase, 1 lowercase, 1 number and 1 special char
        passwordPolicy: helpers.regex(
          'passwordPolicy',
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      },
      role_id: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('commonSettings', ['roles']),
    defaultFormData() {
      return { id: '', email: '', password: '', role_id: '' }
    },
    isEditForm() {
      return this.formMode === FORM_MODE.EDIT
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate() {
      this.$emit('update:isValid', this.isValidForm())
    },
    selectedEmployeeId(newVal) {
      if (newVal) {
        this.dataChange(newVal.id)
      } else {
        this.form = { ...this.defaultFormData }
      }
    },
  },
  mounted() {
    this.rolesOptions = this.roles.map((role) => {
      return {
        value: role.id,
        text: role.name,
      }
    })
    this.form = { ...this.defaultFormData }
    this.dummyPassword = this.form.password
    if (this.formData) {
      this.selectedEmployeeId = {
        id: this.formData.id,
        name: this.formData.name,
      }
      this.customerNameOptions = this.dataCustomer
        .filter((item) => item.login_accepted === LOGIN_STATUS.ACTIVE)
        .map((item) => ({ id: item.id, name: item.name }))
      this.form = { ...this.form, ...this.formData }
      this.dummyPassword = PASSWORD_DUMMY
      this.formMode = FORM_MODE.EDIT
    } else {
      this.customerNameOptions = this.dataCustomer
        .filter((item) => item.login_accepted === LOGIN_STATUS.DEACTIVE)
        .map((item) => ({ id: item.id, name: item.name }))
    }
  },
  methods: {
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check password
      this.checkPassWord(instance, name)
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      if (this.isEditForm) {
        return true
      }
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
    checkPassWord(instance, name) {
      if (name === 'password' && !instance.passwordPolicy) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG07
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    dataChange(id) {
      if (id) {
        this.setFormValue('id', id)
        this.form = { ...this.form, ...this.dataCustomer.find((item) => item.id === id) }
        this.isValidForm()
      }
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    resetDummyPassword() {
      this.dummyPassword = ''
    },
  },
}
</script>
