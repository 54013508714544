<template>
  <multiselect
    v-model="selectedValue"
    :class="`MTMultipleSelect${multiple ? '' : '__single'} ${error ? 'error' : ''} ${
      disabled ? 'disabled' : ''
    }`"
    :options="options"
    :multiple="multiple"
    :track-by="trackBy"
    :label="label"
    :close-on-select="closeOnSelect"
    placeholder="オプション選択"
    select-label="選択のためEnterをクリック"
    selected-label="選択済み"
    deselect-label="削除のためEnterをクリック"
  >
    <span slot="noResult">{{ noResultLabel }}</span>
    <span slot="noOptions"> {{ noOptionsLabel }}</span>
  </multiselect>
</template>
<script>
import isEqual from 'lodash-es/isEqual'

export default {
  name: 'MultiSelect',
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noResultLabel: {
      type: String,
      default: '要素が見つかりません',
    },
    noOptionsLabel: {
      type: String,
      default: 'リストが空です',
    },
  },
  data() {
    return {
      selectedValue: [],
    }
  },
  watch: {
    selectedValue(val) {
      this.$emit('update:value', val)
    },
    value(newVal) {
      if (!isEqual(newVal, this.selectedValue)) {
        this.selectedValue = newVal
      }
    },
  },
}
</script>
