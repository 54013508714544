<template>
  <Layout>
    <page-layout page-titles="アカウント管理" :bread-crumb-items="breadCrumbItems">
      <template #table-content>
        <list-of-internal-member-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
          :show-delete-dialog="showDeleteDialog"
          :has-add-button="!isCustomerUser"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <list-of-internal-member-modal-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
                :data-customer="dataCustomer"
              />
            </c-modal>
            <delete-confirmation :id="dialogDeleteId" :confirm-delete-func="onDeleteConfirm" />
            <change-password-dialog
              :id="dialogChangePasswordId"
              :confirm-change-password="onChangePassword"
              :require-old-password="false"
            >
            </change-password-dialog>
          </template>
        </list-of-internal-member-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import ListOfInternalMemberTable from '@/components/tables/list-of-internal-member-table'
import ListOfInternalMemberModalForm from '@/components/forms/list-of-internal-member-modal-form'
import DeleteConfirmation from '@/components/modals/delete-confirmation-modal'
import { mapActions, mapGetters } from 'vuex'
import pick from 'lodash-es/pick'
import { ALERT_TYPE, LOGIN_STATUS } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { listOfInternalMemberData } from '@/services/list-of-internal-member-service'
import ChangePasswordDialog from '@/components/modals/change-password-modal'

export default {
  name: 'ListOfInternalMember',
  components: {
    DeleteConfirmation,
    ListOfInternalMemberModalForm,
    ListOfInternalMemberTable,
    Layout,
    PageLayout,
    CModal,
    ChangePasswordDialog,
  },
  data() {
    return {
      searchInput: '',
      formData: {},
      dialogId: 'imm-dialog',
      dialogDeleteId: 'del-dialog',
      dialogChangePasswordId: 'change-password-id',
      dialogTitle: '',
      triggerValidate: false,
      isValid: false,
      dataCustomer: [],
      dialogButtons: [],
      requiredCreateKeys: ['id', 'email', 'password', 'role_id', 'login_accepted'],
      requiredEditKeys: ['id', 'email', 'password', 'role_id', 'login_accepted'],
      onAddOrEditConfirm: () => {
        return {}
      },
      dialogTitles: {
        ADD: {
          id: 1,
          text: 'アカウント新規登録',
        },
        EDIT: {
          id: 2,
          text: 'アカウント情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      breadCrumbItems: [
        {
          text: '部署・従業員管理',
          href: '/management-member',
        },
        {
          text: 'アカウント管理',
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isCustomerUser']),
    dialogAddButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
    dialogEditButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: 'パスワード変更',
          variant: 'secondary',
          minWidth: 133,
          size: 'md',
          func: this.showChangePasswordDialog,
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    async showEditDialog(rowData, editSuccessCallback) {
      this.formData = { ...rowData }
      this.dataCustomer = await listOfInternalMemberData.getCustomerData()
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.dialogButtons = this.dialogEditButtons
      this.$bvModal.show(this.dialogId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await listOfInternalMemberData.updateLogin(formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.includes('email') ? MESSAGES.ADMIN_MT.ADM08 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    async showAddDialog(createSuccessCallback) {
      this.formData = null
      this.dataCustomer = await listOfInternalMemberData.getCustomerData()
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            formData.login_accepted = LOGIN_STATUS.ACTIVE
            const { success, data } = await listOfInternalMemberData.updateLogin(formData)
            if (!success) {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: data.email ? MESSAGES.ADMIN_MT.ADM08 : MESSAGES.COMMON.MSG15,
              })
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.ADD.text
      this.dialogButtons = this.dialogAddButtons
      this.$bvModal.show(this.dialogId)
    },

    showDeleteDialog(rowData, deleteSuccessCallback) {
      this.formData = { ...rowData }
      this.callbackFn = deleteSuccessCallback
      this.dialogTitle = this.dialogTitles.DELETE.text
      this.$bvModal.show(this.dialogDeleteId)
    },

    async onDeleteConfirm() {
      const formData = this.formData
      formData.login_accepted = LOGIN_STATUS.DEACTIVE
      const { success } = await listOfInternalMemberData.updateLogin(formData)
      if (success) {
        // delete success
        this.callbackFn && (await this.callbackFn())
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG05,
        })
      } else {
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG15,
        })
      }
      this.$bvModal.hide(this.dialogDeleteId)
    },
    showChangePasswordDialog() {
      this.$bvModal.show(this.dialogChangePasswordId)
    },
    async onChangePassword(formData) {
      const { success } = await listOfInternalMemberData.changePassword(formData, {
        id: this.formData.id,
      })
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      this.$bvModal.hide(this.dialogChangePasswordId)
    },
  },
}
</script>
