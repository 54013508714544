import axios from '@/configs/http/internal-api-axios'
import { extendMasterRead } from '@/utils/function-utils'
import { USER_STATUS } from '@/utils/constants'

const baseUrl = '/api/v1/user'

async function getItems(perPage = 10, currentPage = 1, keyword = '', isMaster = false) {
  let url = `${baseUrl}?login_accepted=1&perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getCustomerData() {
  const url = `${baseUrl}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data.items.filter((item) => item.status === USER_STATUS.ACTIVE)
}

async function updateLogin(formData) {
  const url = `${baseUrl}/${formData.id}/update-login`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function changePassword(formData, options = {}) {
  let url = `api/v1/reset-password/${options.id}`
  const response = await axios
    .patch(url, formData)
    .then((resp) => {
      return {
        success: true,
        data: resp.data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        data: e.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

function getStatus() {
  return [
    { value: 1, text: '在職中' },
    { value: 2, text: '退職' },
  ]
}
function getFields() {
  return [
    { key: 'user_code', label: '従業員ID', sortable: true },
    { key: 'name', label: '従業員名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'role_id', label: '権限', sortable: true },
    { key: 'created_at', label: '登録日時', sortable: true },
    { key: 'last_access', label: '最終ログイン', sortable: true },
    { key: 'action', label: '' },
  ]
}
export const listOfInternalMemberData = {
  getStatus,
  getItems,
  getCustomerData,
  getFields,
  updateLogin,
  changePassword,
}
