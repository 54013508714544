<template>
  <div class="Table InternalTable">
    <!--  table toolbar  -->
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>
    <!-- start layout table -->
    <div class="table-responsive">
      <b-table
        ref="internal-member-table"
        class="table-nowrap align-middle"
        hover
        thead-class="Table__header"
        tbody-tr-class="Table__row"
        :fields="tableHeader"
        :items="items"
        sort-by="user_code"
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>

        <template #cell(role_id)="data">
          <div class="">
            {{ getRoleLabel(data.item.role_id) }}
          </div>
        </template>

        <template v-if="hasAddButton" #cell(action)="data">
          <div class="cell">
            <img
              v-if="!hideEditIcon(data.item)"
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
            <img
              v-if="!hideDeleteIcon(data.item)"
              src="@/assets/icons/ic_delete.svg"
              alt="delete-icon"
              @click.stop.prevent="onDelete(data.item)"
            />
          </div>
        </template>
      </b-table>
      <!-- end layout table -->
    </div>
    <!--   table paging   -->
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <!--    dialog   -->
    <slot name="dialog" />
  </div>
</template>

<script>
import { listOfInternalMemberData } from '@/services/list-of-internal-member-service'
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { mapGetters } from 'vuex'
import { stringToDateJapan } from '@/utils/date-utils'
import { OWNER } from '@/utils/constants'

export default {
  name: 'ListOfInternalMemberTable',
  components: { SearchInput, AddNewButton, TablePaging, SortIcon },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
      items: [],
      role: [],
      tableHeader: listOfInternalMemberData.getFields(),
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['roles']),
    ...mapGetters('user', ['currentUser']),
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange(this.searchInput)
    },
    currentPage() {
      this.handleValueChange(this.searchInput)
    },
  },
  async created() {
    await this.reloadTable()
    this.role = this.roles.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    async reloadTable(isMaster = false) {
      this.searchInput = ''
      const { items, total } = await listOfInternalMemberData.getItems(
        this.perPage,
        this.currentPage,
        this.searchInput,
        isMaster
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    onCreate() {
      this.showAddDialog(() => this.reloadTable(true))
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.reloadTable(true))
    },
    onDelete(rowData) {
      this.showDeleteDialog(rowData, () => this.reloadTable(true))
    },
    async handleValueChange(keyword) {
      const { items, total } = await listOfInternalMemberData.getItems(
        this.perPage,
        this.currentPage,
        keyword
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    getRoleLabel(role) {
      const roleLabel = this.role.find((item) => item.value === role)
      if (roleLabel) return roleLabel.text
      else return null
    },
    convertValue() {
      this.items.map((item) => {
        item.created_at = stringToDateJapan(item.created_at)
        if (item.last_access) {
          item.last_access = stringToDateJapan(item.last_access)
        }
      })
    },
    hideDeleteIcon(item) {
      return item.id === this.currentUser.id || item.is_owner === OWNER.OK
    },
    hideEditIcon(item) {
      return item.id !== this.currentUser.id && item.is_owner === OWNER.OK
    },
  },
}
</script>

<style scoped lang="scss">
.InternalTable {
  table {
    .Table__header {
      th {
        &:nth-of-type(7) {
          width: 100px;
        }
      }
    }
  }
}
</style>
